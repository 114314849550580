

























  
import draggable from 'vuedraggable'
import CButton from '@/components/c-button.vue'
import { Component, Vue } from 'vue-property-decorator'
import sounds from '@/utils/sound.json'

@Component({
  components: {
    draggable,
    CButton
  }
})
export default class Home extends Vue {
  private sounds: unknown[] = sounds
  private favs: unknown[] = []

  log (): void {
    window.localStorage.setItem('fav_sound', JSON.stringify(this.favs))
  }

  setFavs (): void {
    const favString = window.localStorage.getItem('fav_sound')
    if (favString) {
      this.favs = JSON.parse(favString)
    }
  }

  mounted (): void {
    this.setFavs()
  }
}
