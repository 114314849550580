












import { Component, Prop, Vue } from 'vue-property-decorator'


@Component
export default class CButton extends Vue {
  @Prop()
  readonly text!: string

  @Prop()
  readonly sound!: any

  isPlaying = false

  time = 2

  async toggleSound(sound: any): Promise<void> {
    const audio: any = this.$refs.audio
    audio.play()
    this.isPlaying = true
    setTimeout(() => {
      this.isPlaying = false
    }, this.time * 1000 + 750)
  }
}
